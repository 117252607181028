import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import { useServiceCentersStore } from './service-centers'
import { useUtmStore } from './utm'
import type {
  CartOrder,
  CartPromoCode,
  CartUserData,
  Device,
  OrderCreationData,
  Price,
  ServiceCenter,
  PaymentMethod,
} from '~/types/models'
import { ROUTE_NAMES } from '@/constants'

export const useCartStore = defineStore('cart', {
  state: () => ({
    device: ref<Device | null>(),
    paymentMethod: ref<PaymentMethod>(),
    promoCode: ref<CartPromoCode>({
      id: '',
      value: '',
      type: '',
      loading: false,
      valid: false,
    }),
    userData: ref<CartUserData>({
      Email: '',
      CustomerName: '',
      CustomerPhone: '',
    }),
    isLoading: ref<boolean>(false),
    order: ref<CartOrder | null>(),
    qrCode: ref<string | null>(),
    price: ref<Price | null>(),
    serviceCenter: ref<ServiceCenter | null>(),
  }),
  getters: {
    isEmptyCart: state => !state.device,
    isPromocodeApplied: state => state.promoCode.valid,
    totalSum(state) {
      if (!state.device) return 0

      return state.device.Price
    },
    promoCodeDiscount(state): number {
      if (!state.promoCode?.valid || !this.totalSum) return 0

      if (state.promoCode.type === 'Percent') {
        return this.totalSum * (Number(state.promoCode.value) / 100)
      }
      else {
        return Number(state.promoCode.value)
      }
    },
    paymentMethodDiscount(state): number {
      if (
        !state.paymentMethod
        || !state.paymentMethod.discountPercentage
        || !this.totalSum
      )
        return 0

      return Math.floor(
        (this.totalSum / 100) * state.paymentMethod.discountPercentage,
      )
    },
    discountSum(): number {
      // Добавляем скидку по промокодам и за способ оплаты
      return Number(this.promoCodeDiscount) + Number(this.paymentMethodDiscount)
    },
    totalSumAfterDiscount(): number {
      if (!this.totalSum) return 0

      // Вычитаем скидки
      let sum = this.totalSum - this.discountSum

      // Если доплачиваем мы – сумма 0
      if (sum <= 0) {
        sum = 0
      }

      // Округляем
      return sum
    },
  },
  actions: {
    addDeviceToCart(newDevice: Device) {
      this.device = newDevice
    },
    removeDeviceFromCart() {
      this.device = null
    },
    clearCart() {
      this.device = null
    },
    isDeviceAlreadyInCart(targetDevice: Device): boolean {
      return this.device?.Id === targetDevice?.Id
    },
    saveUserData(newUserData: CartUserData) {
      this.userData = newUserData
    },
    async applyPromoCode(inputPromoCode: string) {
      if (!inputPromoCode) return

      this.promoCode.loading = true
      try {
        const { $api } = useNuxtApp()

        const data = await $api.promocodes.getPromocode(inputPromoCode)
        this.promoCode = {
          id: data.id,
          type: data.type,
          value: data.value,
          loading: false,
          valid: true,
        }
      }
      catch (error) {
        this.promoCode.loading = false
        this.promoCode.valid = false
      }
    },
    async createOrder() {
      const utmStore = useUtmStore()
      const serviceCentersStore = useServiceCentersStore()

      if (
        !this.paymentMethod
        || !this.device
        || !this.totalSum
        || !this.userData
        || !serviceCentersStore.current
      )
        return

      try {
        this.isLoading = true

        const orderData: OrderCreationData = {
          ...this.userData,
          BuyoutCaId: this.device.Id,
          ModelPriceId: this.device.ModelPriceId,
          ServiceCenterId: serviceCentersStore.current.id,
          Comment: '',
          Quantity: 1,
          Total: this.totalSum,
          FromSite: 'PedantMarket',
          PaymentType: this.paymentMethod.key,
          UtmTags: utmStore.utmTagsAsString,
          ArrivalAt: null,
          FinalAmount: this.totalSumAfterDiscount,
          DiscountAmount: this.promoCodeDiscount + this.paymentMethodDiscount,
        }

        if (this.promoCode.id) {
          orderData.AppliedPromocodeId = this.promoCode.id
        }

        const { $api } = useNuxtApp()

        const data = await $api.orders.createOrder(orderData)
        this.order = data
        emitMixpanelEventOrderCreated(
          this.device,
          serviceCentersStore.current,
          this.isPromocodeApplied,
          this.paymentMethod,
        )

        return data
      }
      catch (error) {
        // TODO: убрать console
        // eslint-disable-next-line
        console.error(error)
        throw error
      }
    },
    async getOrder(orderId: string) {
      const { $api } = useNuxtApp()
      const router = useRouter()

      return $api.orders
        .getOrder(orderId)
        .then((data) => {
          this.order = data.data as CartOrder
          this.qrCode = data.additional.QrCode
          this.serviceCenter = data.relations.service_center
          this.price = data.relations.price
        })
        .catch(async () => {
          this.order = null
          this.qrCode = null
          this.serviceCenter = null
          this.price = null
          await router.push({ name: ROUTE_NAMES.NOT_FOUND })
        })
    },
    async redirectToOnlinePayment() {
      if (!this.order || !this.device || !this.userData) return

      const paymentParams = {
        Amount: this.totalSumAfterDiscount * 100, // Сумма в копейках,
        OrderId: this.order.Id, // id заказа
        Description: this.device.FullName, // что оплачивает покупатель
        DATA: {
          Phone: this.userData.CustomerPhone, // номер телефона покупателя
        },
      }

      const { $api } = useNuxtApp()
      const data = await $api.tinkoff.createOrder(paymentParams)

      window.location.assign(data.PaymentURL)
    },
    redirectToThanksPage() {
      if (!this.order?.Id) {
        return
      }

      const router = useRouter()

      router.push({
        name: ROUTE_NAMES.THANKS,
        params: {
          id: this.order.Id,
        },
      })
    },
  },
  persist: true,
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot))
}